import React from 'react'
import sbpLogo from '../assets/sbp.png'
import Logo from '../assets/logo.svg'

export function Header() {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '28px'}}>
      <div
        style={{
          background: `url("${sbpLogo}") no-repeat center center`,
          height: 40,
          width: 40,
          backgroundSize: 'contain',
        }}
      />
      <div
        style={{
          background: `url("${Logo}") no-repeat center center`,
          height: 40,
          width: 40,
          backgroundSize: 'contain',
        }}
      />
    </div>
  )
}
