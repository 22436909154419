import React from 'react'
import {useSelector} from 'react-redux'
import {BankList} from '../../components/BankList/BankList'
import {CardForm} from './components/CardForm/CardForm'
import {StatusBlock} from './components/StatusBlock'
import {FatalError} from './components/FatalError'

export function FormPage() {
  const {
    selectedBank,
    update,
    fatalError,
    skipBankSelect,
  } = useSelector(store => store.form)

  return (
    <div style={{
      minHeight: '100vh',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: "border-box",
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <style dangerouslySetInnerHTML={{__html: `

          body {
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background: #18222C;
          }
          
          .list > * {
            display: block;
            position: relative;
            padding-left: 20px;
          }
          
          .list > *:before {
            content: attr(data-number);
            position: absolute;
            left: 0;
          }
        `}} />

        {fatalError ? (
          <FatalError/>
        ) : (
          <>
            {update.data ? (
              <StatusBlock/>
            ) : (
              <>
                {(selectedBank === null && !skipBankSelect) && (
                  <>
                    {/*<div style={{*/}
                    {/*  flexGrow: 1,*/}
                    {/*  marginTop: 80,*/}
                    {/*  display: 'flex',*/}
                    {/*  alignItems: 'center',*/}
                    {/*  justifyContent: 'center'*/}
                    {/*}}>*/}
                    {/*  <CircularProgress*/}
                    {/*    size={46}*/}
                    {/*    thickness={1}*/}
                    {/*    style={{color: '#FFFFFF'}}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div style={{visibility: 'hidden'}}>
                      <BankList/>
                    </div>
                  </>
                )}

                {(selectedBank || skipBankSelect) && (
                  <CardForm />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
