import {Provider} from 'react-redux'
import {dispatch, store} from './store/store'
import {formSlice} from './store/form/formSlice'
import {ThemeProvider} from '@mui/material'
import "./locale/i18n"
import {appThemes} from './themes/themes'

const themeName = process.env.REACT_APP_THEME ?? 'default'

let theme = appThemes[themeName].theme
let FormPage = appThemes[themeName].FormPage
// if(!process.env.REACT_APP_THEME) {
//   theme =  (await import('./themes/default/theme.js')).theme;
//   FormPage = (await import('./themes/default/FormPage.js')).default;
// } else {
//   theme = (await import(`./themes/${process.env.REACT_APP_THEME}/theme.js`)).theme;
//   FormPage = (await import(`./themes/${process.env.REACT_APP_THEME}/FormPage.js`)).default;
// }


function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <FormPage/>
        </ThemeProvider>
      </Provider>
    </div>
  )
}

dispatch(formSlice.actions.clearOldCache())
dispatch(formSlice.actions.restoreFromCache())

export default App;
