import {combineReducers} from "redux"
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga.js'
import {configureStore} from '@reduxjs/toolkit'
import {formReducer} from './form/formSlice'
// import trackerRedux from '@openreplay/tracker-redux';
// import {openReplay} from '../externals/openreplay'

const otherMiddleware = []
const sagaMiddleware = createSagaMiddleware()

// if(openReplay.tracker) {
//   otherMiddleware.push(openReplay.tracker.use(trackerRedux()))
// }

const store = configureStore({
  reducer: combineReducers({
    form: formReducer,
  }),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    ...otherMiddleware,
    sagaMiddleware,
  ]
});

sagaMiddleware.run(rootSaga)

const {dispatch} = store;

export {store, dispatch}

