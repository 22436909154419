import './config/Environment'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import App from './App'

// import './externals/posthog'
// import "./externals/chatWoot"
import "./externals/openreplay"

console.log(process.env.REACT_APP_VERSION)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <App/>
)
