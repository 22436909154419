import {useSelector} from 'react-redux'
import {
  Box, Button, Typography, Switch,
  Alert,
  FormControlLabel, FormControl, FormHelperText, MenuItem, TextField, InputAdornment
} from '@mui/material'
import 'dayjs/locale/ru';
import SendIcon from '@mui/icons-material/Send';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {formSlice} from '../../store/form/formSlice'
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import {useCallback, useEffect, useState} from 'react'
import {dispatch} from '../../store/store'
import {TextMaskCustom} from '../TextMaskCustom'
import * as libPhoneNumber from 'libphonenumber-js'
import {useTranslation} from "react-i18next"
import TelegramIcon from '@mui/icons-material/Telegram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const telegramUserMask = "@################################"
const telegramPhoneMask = '+...............'
const defaultTelegramMask = "^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^"
const defaultWhatsappMask = '+...............'

export function CashDeliveryForm() {
  const {t} = useTranslation('translation', {keyPrefix: 'CashDeliveryForm'})
  const {cashDelivery} = useSelector(store => store.form)
  const [form, setForm] = useState({cashDelivery});
  const [telegramMask, setTelegramMask] = useState(defaultTelegramMask)
  const [whatsAppMask, setWhatsAppMask] = useState(defaultWhatsappMask)

  const [errors, setErrors] = useState({
    username: null,
    receive_time: null,
    address: null,
  })

  const handleChange = (prop) => (event) => {
    let value = (() => {
      if (prop === 'receive_time') {
        return event
      } else if (prop === 'receive_asap') {
        return !!event.target.checked
      } else {
        return event.target.value
      }
    })()

    if (prop === 'username' && form.cashDelivery.messenger === 'whatsapp') {
      let mask = defaultWhatsappMask
      let toCheck = value.replace('+', '').replaceAll(' ', '')

      if (value[0] === '+' && toCheck.length > 3) {
        try {
          const phone = libPhoneNumber.parsePhoneNumber(value)
          if (phone) {
            let format = phone.formatInternational()
            if (format) {
              mask = `${format.replace(/[0-9]/g, '.')}...............`
            }
          }
        } catch (e) {
          //
        }
      }
      setWhatsAppMask(mask)
    }

    if (prop === 'username' && form.cashDelivery.messenger === 'telegram') {
      let type = null
      let toCheck = value.replace('@', '').replace('+', '').replaceAll(' ', '')

      if (toCheck) {
        const number = +toCheck
        if (Number.isNaN(number)) {
          type = 'user'
        } else {
          type = 'phone'
        }
      }

      switch (type) {
        case 'user':
          setTelegramMask(telegramUserMask)
          break
        case 'phone':
          let mask = telegramPhoneMask
          if (value[0] === '+' && toCheck.length > 3) {
            try {
              const phone = libPhoneNumber.parsePhoneNumber(value)
              if (phone) {
                let format = phone.formatInternational()
                if (format) {
                  mask = `${format.replace(/[0-9]/g, '.')}...............`
                }
              }
            } catch (e) {
              //
            }
          }
          setTelegramMask(mask)
          break
        default:
          setTelegramMask(defaultTelegramMask)
      }
    }

    setForm({...form, cashDelivery: {...form.cashDelivery, [prop]: value}});
    setErrors({
      ...errors,
      [prop]: null,
    })
  };

  useEffect(() => {
    dispatch(formSlice.actions.setCashDelivery(form.cashDelivery))
    dispatch(formSlice.actions.saveInCache())
  }, [form.cashDelivery])

  const submitForm = useCallback((cashDelivery) => {
    return function () {
      const errors = {}

      if (!cashDelivery.username) {
        errors.username = 'error.required'
      }

      if (cashDelivery.username && cashDelivery.username[0] === '+') {
        try {
          const phone = libPhoneNumber.parsePhoneNumber(cashDelivery.username)
          if (!phone || !phone.isValid()) {
            errors.username = 'error.incorrectNumber'
          }
        } catch (e) {
          errors.username = 'error.incorrectNumber'
        }
      }

      if (cashDelivery.username && cashDelivery.username[0] === '@') {
        if (cashDelivery.username.length < 6) {
          errors.username = 'error.incorrectLogin'
        }
      }

      if (!cashDelivery.receive_asap && !cashDelivery.receive_time) {
        errors.receive_time = 'error.required'
      }

      if (!cashDelivery.address) {
        errors.address = 'error.required'
      }

      setErrors(errors)

      const valid = Object.entries(errors).every(([key, value]) => !value)

      if (valid) {
        setForm({
          ...form,
          cashDelivery: {
            ...form.cashDelivery,
            isSubmitted: true,
          }
        })
        dispatch(formSlice.actions.setCashDelivery(cashDelivery))
        dispatch(formSlice.actions.fetchData())
      }
    }
  }, [])

  return (
    <div>
      <Typography style={{fontSize: 24}}>
        {!form.cashDelivery.isSubmitted && t('enterCourierData')}
      </Typography>

      <Box mt={2} style={{display: 'flex', flexDirection: 'column', gap: 16}}>
        <FormControl sx={{m: 1, minWidth: 120}}>

          <Alert sx={{mb: 3}} severity="warning">
            <Typography sx={{mb: .5}} style={{fontSize: 16}}>
              {t('saveConfirmationCode')}
            </Typography>
            {t('confirmationRequiredForCourier')}
          </Alert>

          <Box sx={{mb: 3}}>
            <Typography style={{fontSize: 18}}>
              {t('confirmationCodePrompt')} <b>{form.cashDelivery.verification_code}</b>
            </Typography>
          </Box>

          {!form.cashDelivery.isSubmitted && (
            <>
              <TextField
                sx={{mb: 2}}
                size="small"
                select
                label={t('label.messenger')}
                id="messenger"
                value={form.cashDelivery.messenger}
                onChange={handleChange('messenger')}
              >
                <MenuItem value="telegram">
                  <div style={{display: 'flex', justifyItems: 'center', gap: 6}}>
                    <TelegramIcon style={{color: "#1976d2"}}/>
                    <div>
                      Telegram
                    </div>
                  </div>
                </MenuItem>
                <MenuItem value="whatsapp">
                  <div style={{display: 'flex', justifyItems: 'center', gap: 6}}>
                    <WhatsAppIcon style={{color: "#1976d2"}}/>
                    <div>
                      WhatsApp
                    </div>
                  </div>
                </MenuItem>
              </TextField>

              {cashDelivery.messenger === 'whatsapp' && (
                <>
                  <TextField
                    size="small"
                    value={form.cashDelivery.username}
                    onChange={handleChange('username')}
                    label={t('label.phone')}
                    variant="outlined"
                    InputProps={{
                      inputComponent: TextMaskCustom,
                      inputProps: {
                        mask: whatsAppMask,
                        definitions: {
                          ".": /[0-9]/,
                        },
                      }
                    }}
                  />
                  <FormHelperText sx={{mb: 2}} id="messenger-helper" error={errors.username}>
                    {errors.username ? t(errors.username) : null}
                  </FormHelperText>
                </>
              )}

              {cashDelivery.messenger === 'telegram' && (
                <>
                  <TextField
                    add
                    size="small"
                    value={form.cashDelivery.username}
                    onChange={handleChange('username')}
                    label={t('label.login')}
                    variant="outlined"
                    InputProps={{
                      inputComponent: TextMaskCustom,
                      inputProps: {
                        mask: telegramMask,
                        definitions: {
                          ".": /[0-9]/,
                          "#": /[a-zA-Z0-9_]/,
                          "^": /[+@a-zA-Z0-9_]/,
                        },
                      },
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <TelegramIcon style={{color: "#24A1DE"}}/>
                      //   </InputAdornment>
                      // ),
                    }}
                  />
                  <FormHelperText sx={{mb: 2}} id="messenger-helper" error={errors.username}>
                    {errors.username ? t(errors.username) : null}
                  </FormHelperText>
                </>
              )}

              <TextField
                size="small"
                value={form.cashDelivery.address}
                onChange={handleChange('address')}
                label={t('label.address')}
                variant="outlined"
              />
              <FormHelperText sx={{mb: 2}} id="address-helper" error={errors.address}>
                {errors.address ? t(errors.address) : null}
              </FormHelperText>

              <FormControlLabel
                size="small"
                sx={{mb: 2}}
                control={
                  <Switch
                    onChange={handleChange('receive_asap')}
                    checked={form.cashDelivery.receive_asap}
                  />
                }
                label={t('label.immediateDelivery')}
              />

              {!form.cashDelivery.receive_asap && (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                  <MobileDateTimePicker
                    value={form.cashDelivery.receive_time}
                    size="small"
                    label={t('label.deliveryDateTime')}
                    slotProps={{textField: {size: 'small'}}}
                    onChange={handleChange('receive_time')}
                    renderInput={(params) => <TextField size="small" {...params} />}

                  />
                  <FormHelperText sx={{mb: 2}} id="address-helper" error={errors.receive_time}>
                    {errors.receive_time}
                  </FormHelperText>
                </LocalizationProvider>
              )}

              <Alert sx={{mb: 3}} severity="info">
                <Typography sx={{mb: .5}} style={{fontSize: 16}}>
                  {t('attentionWarning')}
                </Typography>
                {t('courierContactUsageNotice')}
              </Alert>
            </>
          )}

          {form.cashDelivery.isSubmitted && (
            <>
              <Alert sx={{mb: 3}}>
                <Typography sx={{mb: .5}} style={{fontSize: 16}}>
                  {t('orderAcceptedMessage')}
                </Typography>
                <Typography style={{fontSize: 14}}>
                  {t('courierConfirmationMessage')}
                </Typography>
              </Alert>
            </>
          )}

          {!form.cashDelivery.isSubmitted && (
            <Button
              size='large'
              variant="contained"
              endIcon={<SendIcon style={{position: 'relative', top: '-1px'}}/>}
              onClick={submitForm(form.cashDelivery)}
            >
              <Typography>
                {t('sendCourier')}
              </Typography>
            </Button>
          )}
        </FormControl>

      </Box>
    </div>
  )
}
