export const sourceBanks = {
  "Sber": {
    "name_en": "Sber",
    "name_ru": "Сбер",
    "key": "sber"
  },
  "T-bank": {
    "name_en": "T-Bank",
    "name_ru": "Т-Банк",
    "key": "tinkoff"
  },
  "VTB": {
    "name_en": "VTB",
    "name_ru": "ВТБ",
    "key": "vtb"
  }
}
