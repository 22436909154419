import {dispatch} from '../store/store'
import {formSlice} from '../store/form/formSlice'
import {Box, Button, Typography} from '@mui/material'
import {redirect} from '../utils/redirect'

export const errorCodes = {
  FORM_PAY_ALREADY_SUBMITED: [
    function ({params, t}) {
      const {finish_url} = params
      return (
        <div>
          {finish_url && (
            <>
              <Button
                onClick={() => redirect(finish_url)}
                sx={{mt: 3}}
                fullWidth
                variant="contained"
                size="large"
              >
                {t('returnToShop')}
              </Button>
            </>
          )}
        </div>
      )
    }
  ],
  NO_TERMINALS_AVAILABLE: [
    function ({params, t}) {
      const {finish_url} = params
      if (!finish_url) {
        return
      }
      return (
        <Box sx={{mt: 3}}>
          <Button
            onClick={() => {
              dispatch(formSlice.actions.selectBank({bank: null}))
              dispatch(formSlice.actions.setFatalError(null))
            }}
            fullWidth
            variant="contained"
            size="large"
          >
            {t('returnToPaymentMethodSelection')}
          </Button>
        </Box>
      )
    },
    function ({t}) {
      return (
        <Box sx={{mt: 3}}>
          <Button
            variant="outlined"
            onClick={() => dispatch(formSlice.actions.update({status: 'cancelled'}))}
            fullWidth
            size="large"
          >
            {t('cancel')}
          </Button>
        </Box>
      )
    },
  ],
}

const defaultItems = [
  function ({params, t}) {
    const {finish_url} = params

    return (
      <div>
        {finish_url && (
          <>
            <Typography sx={{mt: 1}} style={{fontSize: 18}}>
              {t('returnToShopAndRetryPayment')}
            </Typography>

            <Button
              onClick={() => redirect(finish_url)}
              sx={{mt: 3}}
              fullWidth
              variant="contained"
              size="large"
            >
              {t('returnToShop')}
            </Button>
          </>
        )}
      </div>
    )
  }
]

export function getErrorCodeItems(code) {
  if (errorCodes[code]) {
    return errorCodes[code]
  }
  return defaultItems
}
