export const pt = {
  common: {
    title: {
      phone: 'Faça uma tradução',
      card: 'Transferência por número de cartão',
      sbp: 'Faça uma transferência via SBP',
      sberPay: 'Faça uma transferência via Sberpay',
      walletm10: 'Faça uma tradução',
      local_banks: 'Faça uma tradução',
      account: '',
    },
    copied: 'Copied',
  },
  PageHeader: {
    order: '№ do pedido {{order}}',
  },
  BankList: {
    selectBank: 'Selecione o banco',
    selectSourceBank: 'Selecione o banco a partir do qual irá transferir fundos',
    bankSelectionNotice: 'Tenha cuidado ao escolher um banco. Você não pode selecionar um banco novamente.',
  },
  CardForm: {
    amountChangedTitle: 'Os detalhes do valor inicial estão temporariamente indisponíveis. Sugerimos que pague',
    amountChangedText: '{{amount}} {{currency}}',
    amountChangedConfirm: 'Continuar',
    sbpPhone: 'Número do destinatário',
    sbpBank: 'Banco do destinatário',
    sbpFio: 'Nome do destinatário',
    remainingTime: 'Tempo restante:',
    pan: 'Número do cartão',
    phone: 'Número de telefone',
    accountNumber: 'Número de conta',
    recipient: 'Destinatário',
    uploadTransactionReceipt: 'Carregue seu recibo de transação',
    attention: 'Atenção!',
    paymentConfirmed: 'Verifique o status do pagamento',
    cancel: 'Cancelar',
    selectYourMobileDevice: 'Selecione seu dispositivo',
    paymentTypes: {
      card: 'Faça uma transferência usando o número do seu cartão',
      phone: 'Faça uma transferência por número de telefone',
      sbp: '',
      walletm10: 'Faça uma transferência via M10 pelo número de telefone',
      local_banks: 'Faça uma transferência usando o número da sua conta',
      account: 'Faça uma transferência usando o número da sua conta',
    },
    paymentTips: {
      doNotChangeTransferAmount: 'Não altere o valor da transferência',
      doNotSpecifyTransferComment: 'Não inclua um comentário para a tradução',
      ensureRecipientNameAndBankMatchDetails: 'Certifique-se de que o nome e o banco do destinatário correspondem aos dados fornecidos',
      paymentMustBeMadeInOneAmount: 'O pagamento deve ser feito em um único valor',
      pressCancelToSelectAnotherBank: 'Para selecionar outro banco, clique em "Cancelar" e crie o pagamento novamente.',
      transferToAccountNumberAbove: 'Faça uma transferência para o número de conta indicado acima',
      ifUnableToPayPressCancel: 'Se você não conseguiu pagar, clique no botão "Cancelar"',
      enterPaymentAmountAndSubmit: 'No aplicativo, indique o valor do pagamento que você indicou ao reabastecer e clique em "Enviar"',
      card1: 'Transfer the strictly specified amount',
      card2: 'When transferring, check that the card number matches the one specified above',
      card3: 'After the transfer, check the payment status',
      card4: 'If you couldn\'t pay, click the "Cancel" button',
      sberPay1: 'Selecione o seu dispositivo',
      sberPay2: 'Digitalizar o código QR',
      sberPay3: 'Ao pagar na aplicação, indique o valor exato',
      sberPay4: 'Após a transferência, verifique o estado do pagamento',
      sberPay5: 'Se não conseguiu pagar, clique no botão "Cancelar"',
      sberPayMobile1: 'Transferir o valor estritamente especificado',
      sberPayMobile2: 'Após a transferência, verifique o estado do pagamento',
      sberPayMobile3: 'Se não conseguiu pagar, clique no botão "Cancelar"',
      sbp1: 'Transferir o valor estritamente especificado',
      sbp2: 'Ao efetuar uma transferência, verifique se o nome e o banco do destinatário correspondem aos especificados acima',
      sbp3: 'Após a transferência, verifique o estado do pagamento',
      sbp4: 'Se não conseguiu pagar, clique no botão "Cancelar"',
    },
  },
  CashDeliveryForm: {
    enterCourierData: 'Insira os detalhes do correio',
    saveConfirmationCode: 'Salve seu código de verificação',
    confirmationRequiredForCourier: 'Você precisará dele quando encontrar com o correio',
    confirmationCodePrompt: 'Código de confirmação:',
    attentionWarning: 'Tome cuidado!',
    courierContactUsageNotice: 'Os dados especificados serão usados ​​pelo o correio para entrar em contato com você',
    orderAcceptedMessage: 'Obrigado, seu pedido foi aceito.',
    courierConfirmationMessage: 'Aguarde uma mensagem do com correio para confirmação.',
    sendCourier: 'Enviar um courier',
    label: {
      messenger: 'Mensageiro',
      phone: 'Número de telefone',
      login: 'login/número de telefone do telegrama',
      address: 'Endereço para encontro com o courier',
      immediateDelivery: 'Entregar imediatamente',
      deliveryDateTime: 'Data e hora da entrega',
    },
    error: {
      required: 'Campo obrigatório',
      incorrectNumber: 'Número incorreto',
      incorrectLogin: 'Login incorreto',
    },
  },
  StatusBlock: {
    paymentSuccessful: 'Transferência recebida',
    paymentDeclined: 'Tradução não encontrada',
    returnToShop: 'Voltar ao site',
    status: {
      somethingWentWrong: 'Algo deu errado.',
      waitingForFunds: 'Estamos aguardando a chegada dos fundos.',
      operationCancelled: 'A operação foi cancelada.',
      paymentTimeExpired: 'O prazo para pagamento expirou.',
    },
    return: {
      returnToShopAndRetryPayment: 'Pode voltar ao site e repetir o pagamento.',
      returnToShop: 'Pode retornar ao site.',
    },
  },
  Upload: {
    ok: 'OK',
    upload: 'Download',
    error: 'Erro',
  },
  FatalError: {
    somethingWentWrong: 'Algo deu errado.',
    returnToShopAndRetryPayment: 'Pode voltar ao site e repetir o pagamento.',
    returnToShop: 'Voltar ao site',
    returnToPaymentMethodSelection: 'Voltar a escolher um método de pagamento',
    cancel: 'Cancelar',
    code: {
      NO_TERMINALS_AVAILABLE: 'Não existem terminais disponíveis para este método de pagamento.',
      FORM_PAY_ALREADY_SUBMITED: 'Por favor, retorne à loja para pagar novamente.',
      COMMON_ERROR: 'Erro durante o pagamento.',
      DUPLICATE_ORDER_ERROR: 'Já existe um pedido com este número.',
      ROUTE_ERROR: 'Não há possibilidade de efetuar este pagamento.',
    },
  }
}
