import { useSelector } from 'react-redux'
import { Box, Card, CardActionArea, CircularProgress, Typography } from '@mui/material'
import { getBankInfo } from '../../config/bankInfo'
import { useCallback, useEffect, useState, useLayoutEffect } from 'react'
import { dispatch } from '../../store/store'
import { formSlice } from '../../store/form/formSlice'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useTranslation } from "react-i18next";
import AndroidIcon from '../../assets/icons/android-icon.svg'
import iOSIcon from '../../assets/icons/ios-icon.svg'
import { getMobileOperatingSystem } from '../../utils/getMobileOperatingSystem'

export function DeviceList(props) {
  const { isSberpay = false } = props
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'DeviceList' })
  const { selectedDevice } = useSelector(store => store.form)
  const [autoDetectedDevice, setAutoDetectedDevice] = useState()
  
  const onClick = useCallback((device) => {
    dispatch(formSlice.actions.selectDevice({ device }))
  }, [])

  useEffect(() => {
    setAutoDetectedDevice(getMobileOperatingSystem())
  }, [])

  useEffect(() => {
    if(autoDetectedDevice){
      onClick(autoDetectedDevice)
    }
  }, [autoDetectedDevice])

  return (
    <div>
      <Typography style={{ fontSize: 24 }}>
        Выберите тип устройства
      </Typography>

      <Box mt={2} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Card sx={{ boxShadow: 4 }} style={{ height: 80 }} onClick={() => onClick('Android')}>
          <CardActionArea style={{ height: 80, display: 'flex' }}>
            <div
              style={{
                background: `url("${AndroidIcon}") no-repeat center center`,
                height: 80,
                width: 80,
              }}
            />
            <div style={{ flexGrow: 1, margin: '8px' }}>
              <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                Android
              </Typography>
            </div>
            <div style={{ margin: '8px' }}>
              <NavigateNextIcon style={{ fontSize: 32, color: '#9c9c9c' }} />
            </div>
          </CardActionArea>
        </Card>
        <Card sx={{ boxShadow: 4 }} style={{ height: 80 }} onClick={() => onClick('iOS')}>
          <CardActionArea style={{ height: 80, display: 'flex' }}>
            <div
              style={{
                background: `url("${iOSIcon}") no-repeat center center`,
                height: 80,
                width: 80,
              }}
            />
            <div style={{ flexGrow: 1, margin: '8px' }}>
              <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                iOS
              </Typography>
            </div>
            <div style={{ margin: '8px' }}>
              <NavigateNextIcon style={{ fontSize: 32, color: '#9c9c9c' }} />
            </div>
          </CardActionArea>
        </Card>
      </Box>

    </div>
  )
}
