import {Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {useState} from 'react'
import {getErrorCodeItems} from '../../config/errorCodes'
import {useTranslation} from "react-i18next"

export function FatalError() {
  const {t} = useTranslation('translation', {keyPrefix: 'FatalError'})
  const form = useSelector(store => store.form)
  const {params, fatalError} = form

  const [items] = useState(getErrorCodeItems(fatalError.code))

  let message = t('somethingWentWrong')
  if(fatalError.code && fatalError.code in {
    NO_TERMINALS_AVAILABLE: 1,
    FORM_PAY_ALREADY_SUBMITED: 1,
    COMMON_ERROR: 1,
    DUPLICATE_ORDER_ERROR: 1,
    ROUTE_ERROR: 1,
  }) {
    message = t(`code.${fatalError.code}`)
  }

  return (
    <div>
      <Typography style={{fontSize: 24}}>
        {message}
      </Typography>

      {items.map((Component, index) => (
        <Component
          key={`key=${index}`}
          params={params}
          fatalError={fatalError}
          t={t}
        />
      ))}
    </div>
  )
}
