
export const nowToList = [
  {
    value: 'sber_card',
    label: 'Инструкция для Сбера',
  },
  {
    value: 'sber_sbp',
    label: 'Инструкция для Сбера',
  },
  {
    value: 'tinkoff_card',
    label: 'Инструкция для Тинькофф',
  },
  {
    value: 'tinkoff_sbp',
    label: 'Инструкция для Тинькофф',
  },
  // {
  //   value: 'vtb_card',
  //   label: 'Инструкция для ВТБ P2P',
  // },
  // {
  //   value: 'vtb_sbp',
  //   label: 'Инструкция для ВТБ СБП',
  // },
]
