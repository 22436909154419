import React from "react"
import {Box, IconButton, Tooltip, Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {getCurrencySymbol} from '../../config/currency'
import {useTranslation} from "react-i18next"
import {Environment} from "../../config/Environment"
import {copyToClipboard} from "../../utils/copyToClipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ClickAwayListener from '@mui/material/ClickAwayListener'

export function PageHeader(props) {
  const {hideCopy} = props
  const {t} = useTranslation('translation', {keyPrefix: 'PageHeader'})
  const {t : ct} = useTranslation('translation', {keyPrefix: 'common'})
  const {params} = useSelector(store => store.form)
  const {formData} = useSelector(store => store.form)

  const [toolTipsState, setToolTipsState] = React.useState({
    amount: {
      isOpen: false,
      timeout: null,
    }
  });
  const handleTooltipClose = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        isOpen: false,
        timeout: null,
      }
    })
  };
  const handleTooltipOpen = (key) => {
    clearTimeout(toolTipsState[key].timeout)
    setToolTipsState({
      ...toolTipsState,
      [key]: {
        isOpen: true,
        timeout: setTimeout(() => {
          handleTooltipClose(key)
        }, 500)
      }
    })
  };

  const order = params.merchant_order || params.order
  const invoiceId = formData?.data?.invoice_id || params.invoice_id

  let amountRaw = params.amount

  if (formData?.data?.is_amount_changed) {
    amountRaw = formData?.data?.amount
  }

  const amount = new Intl.NumberFormat('ru-Ru').format(amountRaw).replace(',', '.')

  return (
    <div>
      {!Environment.REACT_APP_HIDE_PAYMENT_ID && (
        <>
          {order && (
            <Typography style={{fontSize: 20}}>
              {t('order', {order})}
            </Typography>
          )}

          {invoiceId && (
            <Typography variant="subtitle1" style={{color: '#AEAEAE', fontSize: 14}}>
              {invoiceId}
            </Typography>
          )}
        </>
      )}

      {amount && amountRaw && (
        <Box mt={2} mb={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant="h4">
            {amount} {getCurrencySymbol(params.currency)}
          </Typography>

          {!hideCopy && (
            <ClickAwayListener onClickAway={() => handleTooltipClose('amount')}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => handleTooltipClose('amount')}
                open={toolTipsState.amount.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={ct('copied')}
              >
                <IconButton
                  style={{marginTop: -3}}
                  onClick={() => {
                    copyToClipboard(`${Number(amountRaw)}`)
                    handleTooltipOpen('amount')
                  }}
                >
                  <ContentCopyIcon color="primary"/>
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          )}
        </Box>
      )}
    </div>
  )
}
