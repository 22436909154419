import {Button, Typography, useMediaQuery, useTheme} from '@mui/material'
import {useSelector} from 'react-redux'
import React from 'react'
import {useTranslation} from "react-i18next"
import {PincoHeader} from './PincoHeader'
import {redirect} from '../../../utils/redirect'

export function PincoFatalError() {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))

  const {t} = useTranslation('translation', {keyPrefix: 'FatalError'})
  const form = useSelector(store => store.form)
  const {params, fatalError} = form
  const {finish_url} = params

  let message = t('somethingWentWrong')
  if (fatalError.code && fatalError.code in {
    NO_TERMINALS_AVAILABLE: 1,
    FORM_PAY_ALREADY_SUBMITED: 1,
    COMMON_ERROR: 1,
    DUPLICATE_ORDER_ERROR: 1,
    ROUTE_ERROR: 1,
  }) {
    message = t(`code.${fatalError.code}`)
  }

  return (
    <div className={`card center ${mobile ? 'mobile' : null}`}>
      <PincoHeader/>

      <Typography style={{fontSize: 24, color: '#fff'}}>
        {message}
      </Typography>

      {finish_url && (
        <div style={{color: '#FFF'}}>
          <Typography sx={{mt: 2}} style={{fontSize: 18}}>
            {t('returnToShopAndRetryPayment')}
          </Typography>

          <Button
            onClick={() => redirect(finish_url)}
            sx={{mt: 3}}
            fullWidth
            variant="contained"
            size="large"
          >
            {t('returnToShop')}
          </Button>
        </div>
      )}
    </div>
  )
}
