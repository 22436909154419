import React from 'react'
import {IMaskInput} from 'react-imask'
// ----------------------------------------------------------------------

export const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      overwrite
    />
  )
})
