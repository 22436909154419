import {createTheme} from '@mui/material/styles'

export const theme = createTheme({
  cssVariables: true,
  palette: {
    primary: {
      main: '#FB6900',
    },
    // secondary: {
    //   main: '#0F0',
    // },
  },
})
