import React, {useEffect, useRef} from "react"
import QRCodeStyling from "qr-code-styling"
import {Box, Paper, Typography, useMediaQuery, useTheme} from "@mui/material"

const qrCode = new QRCodeStyling({
  width: 368,
  height: 368,
  // image: '/sber-logo.svg',
  // margin: 2,
  qrOptions: {
    typeNumber: 0,
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 16,
    crossOrigin: 'anonymous',
  },
  dotsOptions: {
    color: '#000',
    type: 'square' // square
  },
  backgroundOptions: {
    color: '#FFF',
  },
  cornersSquareOptions: {
    color: '#000',
    type: 'extra-rounded',
  },
  cornersDotOptions: {
    color: '#000',
    type: 'square',
  }
})

export function QRCode(props) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))
  const qrRef = useRef(null)

  useEffect(() => {
    if (qrRef.current) {
      qrCode.append(qrRef.current)
    }
  }, [])

  useEffect(() => {
    qrCode.update({
      data: props.data,
      image: props.icon ? `/${props.icon}.svg` : '/sber-logo.svg',
    });
  }, [props.data]);

  useEffect(() => {
    qrCode.update({
      width: mobile ? 320 : 368,
      height: mobile ? 320 : 368,
      image: props.icon ? `/${props.icon}.svg` : '/sber-logo.svg',
    });
  }, [mobile]);

  return (
    <>
      {/*<Paper variant="outlined">*/}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        ref={qrRef}
      />
      {/*</Paper>*/}
    </>
  )
}
