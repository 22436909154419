import DescriptionIcon from '@mui/icons-material/Description'
import AddIcon from '@mui/icons-material/Add'
import {CircularProgress} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import './upload.css'
import {useTranslation} from "react-i18next";

export function Upload(props) {
  const {t, i18n} = useTranslation('translation', {keyPrefix: 'Upload'})
  const {files} = props

  return (
    <div style={{display: 'flex', gap: 16, flexWrap: 'wrap'}}>
      {files.map((file) => {
        return (
          <div
            key={file.key}
            style={{
              flexShrink: 0,
              flexGrow: 0,
              width: 104,
              height: 104,
              background: file.status === 'error' ? 'rgba(255, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0.04)',
              // border: `1px solid ${file.status === 'error' ? '#ef5350' : ' rgba(0, 0, 0, 0.15)'}`,
              border: `1px solid rgba(0, 0, 0, 0.15)`,
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {file.status === 'pending' && (
              <CircularProgress
                color="success"
                style={{
                  color: '#D9D9D9',
                  fontSize: 48,
                }}
              />
            )}

            {file.status === 'error' && (
              <>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                  <CloseIcon
                    style={{
                      color: '#ef5350',
                      fontSize: 48,
                    }}
                  />
                  {t('error')}
                </div>
              </>
            )}

            {file.status === 'success' && (
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <CheckCircleOutlinedIcon
                  color="success"
                  style={{
                    // color: '#D9D9D9',
                    fontSize: 48,
                  }}
                />
                {t('ok')}
              </div>
            )}
          </div>
        )
      })}
      <div>
        <label
          htmlFor="file"
          className={'UploadBox'}
          style={{
            flexShrink: 0,
            flexGrow: 0,
            width: 104,
            height: 104,
            background: 'rgba(0, 0, 0, 0.04)',
            border: '1px dashed rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <AddIcon
              style={{
                color: '#acacac',
                fontSize: 48,
              }}
            />
            {t('upload')}
          </div>
        </label>
        <input
          style={{display: 'none'}}
          id="file" type="file"
          onChange={(e) => {
            const {target} = e
            const fileList = target.files;
            if (fileList.length && props.onChange) {
              props.onChange(fileList[0])
            }
            target.value = null;
          }}
        />
      </div>
    </div>
  )
}
