import {sberFullLogo, sberShortLogo} from '../assets/banks/sber/sberImages'
import {tinkoffFullLogo, tinkoffShortLogo} from '../assets/banks/tinkoff/tinkoffImages'
import {alfaFullLogo, alfaShortLogo} from '../assets/banks/alfa/alfaImages'
import {sbpFullLogo, sbpShortLogo} from '../assets/banks/sbp/sbpImages'
import {anyShortLogo} from '../assets/banks/any/anyImages'
import {m10ShortLogo} from '../assets/banks/m10/m10Images'
import {raiffeisenShortLogo} from '../assets/banks/raiffeisen/raiffeisenImages'
import {KapitalBankShortLogo} from '../assets/banks/KapitalBank/KapitalBankImages'
import {rabitabankShortLogo} from '../assets/banks/RABITABANK/rabitabankImages'
import {akartShortLogo} from '../assets/banks/Akart/AkartImages'
import {atbShortLogo} from '../assets/banks/ATBbank/atbImages'
import {abbShortLogo} from '../assets/banks/ABB/abbImages'

const info = {
  empty: {
    shortLogo: anyShortLogo,
  },
  any: {
    shortLogo: anyShortLogo,
  },
  raif: {
    shortLogo: raiffeisenShortLogo,
  },
  raiffeisen: {
    shortLogo: raiffeisenShortLogo,
  },
  m10: {
    shortLogo: m10ShortLogo,
    name: " ",
  },
  'м10': {
    shortLogo: m10ShortLogo,
    name: " ",
  },
  sbp: {
    shortLogo: sbpShortLogo,
    fullLogo: sbpFullLogo,
  },
  alfa: {
    shortLogo: alfaShortLogo,
    fullLogo: alfaFullLogo,
  },
  tinkoff: {
    shortLogo: tinkoffShortLogo,
    fullLogo: tinkoffFullLogo,
  },
  sber: {
    shortLogo: sberShortLogo,
    fullLogo: sberFullLogo,
  },
  kapitalbank: {
    shortLogo: KapitalBankShortLogo,
    fullLogo: KapitalBankShortLogo,
  },
  rabitabank: {
    shortLogo: rabitabankShortLogo,
  },
  akart: {
    shortLogo: akartShortLogo,
  },
  "atb bank": {
    shortLogo: atbShortLogo,
  },
  abb: {
    shortLogo: abbShortLogo,
  },
  phone: {
    
  }
}

export function getBankInfo(bankKey) {
  if(info[bankKey.toString().toLocaleLowerCase()]) {
    return info[bankKey.toString().toLocaleLowerCase()]
  }
  return {
    ...info.empty,
  }
}
