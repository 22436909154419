import {Button, CircularProgress, Typography, useMediaQuery, useTheme} from '@mui/material'
import {redirect} from '../../../utils/redirect'
import {useSelector} from 'react-redux'
import {updateStatuses} from '../../../config/updateStatuses'
import {formSlice} from '../../../store/form/formSlice'
import React, {useEffect} from 'react'
import {dispatch} from '../../../store/store'
import {sbankStatuses} from '../../../config/sbankStatuses'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import {useTranslation} from "react-i18next"
import {getRequisiteType} from "../../../config/paymentTypes"
import {PincoHeader} from './PincoHeader'

export function PincoStatusBlock() {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))
  const {t} = useTranslation('translation', {keyPrefix: 'StatusBlock'})
  const form = useSelector(store => store.form)
  const {params, update, formData} = form
  const {finish_url} = params

  const payloadStatus = update.data.payload_status
  const paymentStatus = form.status?.data?.payment_status ?? sbankStatuses.PENDING

  let statusText = t('status.somethingWentWrong')

  useEffect(() => {
    dispatch(formSlice.actions.status())
    const intervalId = setInterval(() => {
      dispatch(formSlice.actions.status())
    }, 2e3)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  switch (payloadStatus) {
    case updateStatuses.paid:
      statusText = t('status.waitingForFunds')
      break
    case updateStatuses.cancelled:
      statusText = t('status.operationCancelled')
      break
    case updateStatuses.timeout:
      statusText = t('status.paymentTimeExpired')
      break
    default:
      break
  }

  let amountRaw = params.amount
  if (formData?.data?.is_amount_changed) {
    amountRaw = formData?.data?.amount
  }
  const amount = new Intl.NumberFormat('ru-Ru').format(amountRaw).replace(',', '.')

  return (
    <div className={`card center ${mobile ? 'mobile' : null}`} style={{color: '#FFF'}}>
      <PincoHeader/>

      <Typography style={{fontSize: 16, textAlign: 'center'}}>
        {!(paymentStatus === sbankStatuses.COMPLETE || paymentStatus === sbankStatuses.FAILED) && (statusText)}
        {paymentStatus === sbankStatuses.FAILED && (t('paymentDeclined'))}
        {paymentStatus === sbankStatuses.COMPLETE && (t('paymentSuccessful'))}
      </Typography>

      <Typography style={{fontSize: 16, textAlign: 'center', marginTop: '32px'}}>
        {amount} RUB
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {paymentStatus === sbankStatuses.PENDING && (
          <div style={{flexGrow: 1, margin: `32px 0`, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress
              size={46}
              thickness={1}
              style={{color: '#FFFFFF'}}
            />
          </div>
        )}
        {paymentStatus === sbankStatuses.COMPLETE && (
          <div
            style={{flexGrow: 1, margin: `32px 0`, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CheckCircleIcon
              style={{fontSize: 100, color: '#00B241'}}
            />
          </div>
        )}
        {paymentStatus === sbankStatuses.FAILED && (
          <div
            style={{flexGrow: 1, margin: `32px 0`, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ErrorIcon
              style={{fontSize: 100, color: '#FF2201'}}
            />
          </div>
        )}
      </div>

      {finish_url && (
        <>
          <Button
            onClick={() => redirect(finish_url)}
            sx={{mt: 3}}
            fullWidth
            variant="contained"
            size="large"
          >
            {t('returnToShop')}
          </Button>
        </>
      )}
    </div>
  )
}
