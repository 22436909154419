import React from 'react'
import sbpLogo from '../assets/sbp.png'
import pincoLogo from '../assets/pinco.png'

export function PincoHeader() {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '28px'}}>
      <div
        style={{
          background: `url("${sbpLogo}") no-repeat center center`,
          height: 40,
          width: 40,
          backgroundSize: 'contain',
        }}
      />
      <div
        style={{
          background: `url("${pincoLogo}") no-repeat center center`,
          height: 25,
          width: 75,
          backgroundSize: 'contain',
        }}
      />
    </div>
  )
}
