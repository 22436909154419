import Tracker from '@openreplay/tracker'
import {Environment} from '../config/Environment'

const href = window.location.href
const url = new URL(href)

const params = {}
for (const key of url.searchParams.keys()) {
  params[key] = url.searchParams.get(key)
}

let isEnabled = false

try {
  const json = JSON.parse(Environment.REACT_APP_OPENREPLAY_IS_ENABLED)
  if (json === true && typeof json === 'boolean') {
    isEnabled = Environment.REACT_APP_OPENREPLAY_PROJECT_KEY
  }
} catch (e) {
  //
}

let promiseResolve = null

export const openReplay = {
  trackerIsReady: isEnabled ? Promise.any([
    new Promise((res) => promiseResolve = res),
    new Promise(res => setTimeout(() => res(), 3e3))
  ]) : Promise.resolve(),
  tracker: null,
  flags: [],
  asyncGetFlag: async (key) => {
    if (!isEnabled) {
      return null
    }
    await openReplay.trackerIsReady
    const flag = openReplay.flags.find(f => f.key === key)
    if (!flag) {
      return undefined
    }
    return flag.value
  },
  getFlag: (key) => {
    if (!isEnabled) {
      return null
    }
    const flag = openReplay.flags.find(f => f.key === key)
    if (!flag) {
      return undefined
    }
    try {
      const json = JSON.parse(flag.value)
      return json
    } catch (e) {
      return undefined
    }
  },
}

if (isEnabled) {
  try {
    const tracker = new Tracker({
      projectKey: Environment.REACT_APP_OPENREPLAY_PROJECT_KEY,
      ingestPoint: Environment.REACT_APP_OPENREPLAY_INGEST_POINT,
      __DISABLE_SECURE_MODE: true,
      flags: {
        onFlagsLoad: (flags) => {
          if (promiseResolve) {
            openReplay.flags = flags
            promiseResolve()
          }
          // handle active flags
        },
      }
    })
    await tracker.start()
    openReplay.tracker = tracker

    tracker.setUserID(params.customer_id ?? params.customer)
    for (const key of url.searchParams.keys()) {
      tracker.setMetadata(key, url.searchParams.get(key))
    }
  } catch (e) {
    console.log('e', e)
  }
}
