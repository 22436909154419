import {useSelector} from 'react-redux'
import {Box, Card, CardActionArea, CircularProgress, Typography} from '@mui/material'
import {getBankInfo} from '../../config/bankInfo'
import {useCallback, useEffect} from 'react'
import {dispatch} from '../../store/store'
import {formSlice} from '../../store/form/formSlice'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {useTranslation} from "react-i18next";

export function BankList(props) {
  const {isSourceBanks = false} = props
  const {t, i18n} = useTranslation('translation', { keyPrefix: 'BankList' })
  const {banks, sourceBanks} = useSelector(store => store.form)

  useEffect(() => {
    if(!isSourceBanks){
      dispatch(formSlice.actions.fetchBanks())
    }
  }, [])

  const selectBank = useCallback((bank) => {
    return function () {
      if(!isSourceBanks) {
        dispatch(formSlice.actions.selectBank({bank}))
      } else {
        dispatch(formSlice.actions.selectSourceBank({bank}))
      }
    }
  }, [])

  if (banks.loading && !isSourceBanks) {
    return (
      <div style={{flexGrow: 1, marginTop: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress
          size={120}
          thickness={2}
        />
      </div>
    )
  }

  return (
    <div>
      <Typography style={{fontSize: 24}}>
        {!isSourceBanks && t('selectBank')}
        {isSourceBanks && t('selectSourceBank')}
      </Typography>

      <Box mt={2} style={{display: 'flex', flexDirection: 'column', gap: 16}}>
        {Object.entries(isSourceBanks ? sourceBanks.data : banks.data).map(([bankKey, data]) => {
          const bankInfo = getBankInfo(bankKey)
          const bankData = isSourceBanks ? sourceBanks.data[bankKey] : banks.data[bankKey]

          return (
            <Card sx={{boxShadow: 4}} key={bankKey} style={{height: 80}}>
              <CardActionArea style={{height: 80, display: 'flex'}} onClick={selectBank(data)}>
                <div
                  style={{
                    background: `url("${bankInfo.shortLogo}") no-repeat center center`,
                    height: 80,
                    width: 80,
                  }}
                />
                <div style={{flexGrow: 1, margin: '8px'}}>
                  <Typography style={{fontSize: 18, fontWeight: 600}}>
                    {bankData[`name_${i18n.language}`]}
                  </Typography>
                </div>
                <div style={{margin: '8px'}}>
                  <NavigateNextIcon style={{fontSize: 32, color: '#9c9c9c'}}/>
                </div>
              </CardActionArea>
            </Card>
          )
        })}
      </Box>

      <Typography mt={2} style={{fontSize: 16, color: '#A2ADB8'}}>
        {t('bankSelectionNotice')}
      </Typography>

    </div>
  )
}
